




































import Vue from "vue";

export default Vue.extend({
  name: "NavBarBottom",
  data() {
    return {
      navItems: [
        { title: "Home", icon: "mdi-home", path: "/" },
        { title: "About", icon: "mdi-home", path: "/about" },
        { title: "Photos", icon: "mdi-image-frame", path: "/photos" },
        {
          title: "LandingPage01",
          icon: "mdi-page-layout-header",
          path: "/landingpage-01"
        },
        {
          title: "LandingPage02",
          icon: "mdi-page-layout-header",
          path: "/landingpage-02"
        }
      ],
      active: true,
      drawer: false,
      group: null
    };
  },
  methods: {
    toggleDarkMode: function() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    }
  },
  mounted() {
    const theme = localStorage.getItem("dark_theme");

    if (theme) {
      theme === "true"
        ? (this.$vuetify.theme.dark = true)
        : (this.$vuetify.theme.dark = false);
    } else if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      this.$vuetify.theme.dark = true;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    }
  }
});
