import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import NavBar from "@/components/navigation/NavBarTop.vue";
import NavBottom from "@/components/navigation/NavBarBottom.vue";
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    components: { default: Home, navigation: NavBar, footer: NavBottom }
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: {
      default: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
      navigation: NavBar,
      footer: NavBottom
    }
  },
  {
    path: "/photos",
    name: "Photos",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: {
      default: () => import(/* webpackChunkName: "about" */ "../views/Photos.vue"),
      footer: NavBottom
    }
  },
  {
    path: "/favourites",
    name: "Favourites",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/landingpage-01",
    name: "LandingPage01",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: {
      default: () =>
        import(/* webpackChunkName: "about" */ "../views/LandingPages/LandingPage01.vue"),
      footer: NavBottom
    }
  },
  {
    path: "/landingpage-02",
    name: "LandingPage02",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: {
      default: () =>
        import(/* webpackChunkName: "about" */ "../views/LandingPages/LandingPage02.vue"),
      footer: NavBottom
    }
  }
];

const router = new VueRouter({
  //mode: "history",
  routes
});

export default router;
